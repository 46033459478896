import React, { ReactElement, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { useMetadata } from '../hooks/useMetadata'
import { useEventListener } from '../hooks/useEventListener'
import { useCookie } from '../hooks/useCookie'
import { fonts, Fontface } from '../fonts'

import { Navigation } from './navigation'
import { Splash } from './splash'
import { Sidebar } from './sidebar'
import { Subscribe } from './subscribe'
import { Footer } from './footer'

interface LayoutProps {
  children?: ReactElement
  pageContext: any
}

const Layout = ({ children, pageContext }: LayoutProps) => {
  const { origin, pathname } = useLocation(),
    { prismicMetadata } = useMetadata(),
    defaultBackgroundColor: string =
      prismicMetadata?.data?.default_background_color ?? `#FFF`,
    defaultTextColor = prismicMetadata?.data?.default_text_color ?? `#000`,
    defaultLinkHoverColor =
      prismicMetadata?.data?.default_link_hover_color ?? `#000`,
    siteTitle = prismicMetadata?.data?.site_title ?? `Luke Edward Hall`,
    siteTitleDescription =
      prismicMetadata?.data?.site_title_description ??
      `Artist, Designer and Columnist`,
    siteTitleDivider = prismicMetadata?.data?.site_title_divider ?? `|`

  const splashPages = prismicMetadata?.data?.splash_pages,
    random = pageContext?.random ?? Math.random(),
    splashPage = splashPages
      ? splashPages[Math.floor(random * splashPages.length)]
      : undefined,
    splashPageImage = splashPage?.image?.gatsbyImageData,
    [splashLoaded, setSplashLoaded] = useState(
      typeof splashPageImage === `undefined`
    ),
    [splashDismissed, setSplashDismissed] = useState(
      typeof splashPageImage === `undefined`
    ),
    dismissSplash = () =>
      splashLoaded && !splashDismissed && setSplashDismissed(true),
    handleLoad = () => !splashLoaded && setSplashLoaded(true),
    renderSplash = !splashDismissed

  useEffect(() => {
    if (!splashDismissed) document.body.classList.add('overflow-hidden')
    else document.body.classList.remove('overflow-hidden')
  }, [splashDismissed])

  useEffect(() => {
    if (splashLoaded || !renderSplash)
      document.body.classList.remove('invisible')
  }, [splashLoaded, renderSplash])

  const [gdpr] = useCookie(`gdpr`, true)

  // When GDPR cookie changes
  useEffect(() => {
    // If the banner has been dismissed, do nothing
    if (!gdpr) return

    // If the banner is not dismissed, clear any existing cookies
    const cookies = Cookies.get(),
      cookieNames = Object.keys(cookies)
    cookieNames.forEach((name) => Cookies.remove(name))
  }, [gdpr])

  useEventListener(`click`, (e) => {
    if (!e) return
    if (!e.target) return
    if ((e.target as HTMLElement)?.tagName !== `A`) return
    if (!(e.target as HTMLAnchorElement).href) return
    const href = (e.target as HTMLAnchorElement).href,
      state = JSON.parse(
        (e.target as HTMLAnchorElement).getAttribute(`data-state`) || `{}`
      ),
      path = href.replace(origin, ``)

    if (path.substr(0, 1) === `/`) {
      e.preventDefault()
      navigate(path, { state })
    }
  })

  return (
    <div className="layout antialiased text-justify transition duration-1000">
      <Helmet
        defaultTitle={`${siteTitle} ${siteTitleDivider} ${siteTitleDescription}`}
        titleTemplate={`%s | ${siteTitle}`}
      >
        <html lang="en-GB" data-pathname={pathname} />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="twitter:site" content="@LukeEdwardHall" />
        <meta name="twitter:creator" content="@LukeEdwardHall" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          property="og:title"
          content={`${siteTitle} ${siteTitleDivider} ${siteTitleDescription}`}
        />

        <script type="text/javascript">{`
          window['ga-disable-UA-104066177-1'] = ${gdpr};
          if(typeof window.gtag === 'function') gtag('config', 'UA-104066177-1');
        `}</script>

        <style type="text/css">{`
          .text-default { color: ${defaultTextColor}; }
          .text-invert { color: ${defaultBackgroundColor}; }
          .bg-invert { background-color: ${defaultTextColor}; }
          .bg-hover { background-color: ${defaultLinkHoverColor}; }
          html {
            color: ${defaultTextColor};
            background-color: ${defaultBackgroundColor};
          }
          .sidebar { 
            color: ${defaultBackgroundColor};
            background-color: ${defaultTextColor};
          }
          a:hover,
          input::placeholder { color: ${defaultLinkHoverColor}; }
          button { border-color: ${defaultBackgroundColor}; }
          button:hover {
            color: ${defaultTextColor};
            background-color: ${defaultBackgroundColor};
          }
          *:focus { outline-color: ${defaultLinkHoverColor}; }
        `}</style>
      </Helmet>
      <Fontface
        count="https://hello.myfonts.net/count/3bdebf"
        display="swap"
        fonts={{
          Marfa: fonts.Marfa,
          Bell: fonts.Bell,
        }}
      />
      {renderSplash && (
        <Splash
          dismiss={dismissSplash}
          load={handleLoad}
          prismicMetadata={prismicMetadata}
          random={random}
        />
      )}
      <Navigation splashPage={renderSplash} />
      <main>{children}</main>
      <Sidebar id="gdpr">
        {(close) => (
          <>
            <p className="text-justify">
              To provide the best experience, our site uses cookies to store
              information. Please assist us in this effort by accepting cookies.
            </p>
            <button
              className="border m-0"
              onClick={() => close({ expires: 365 })}
            >
              Accept
            </button>
            <button onClick={close}>Refuse</button>
          </>
        )}
      </Sidebar>
      <Subscribe />
      <Footer />
    </div>
  )
}

export default Layout
export { Layout }
