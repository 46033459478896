import React, { lazy, ReactElement } from 'react'
import { Layout } from './layout'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utilities'

const repositoryConfigs = [
  {
    repositoryName: `lukeedwardhall`,
    linkResolver,
    componentResolver: {
      category: lazy(() => import('../templates/category')),
      page: lazy(() => import('../templates/page')),
      project: lazy(() => import('../templates/project')),
    },
  },
]

const Wrapper = ({ element, props }: { element: ReactElement; props: any }) => {
  // Supply preview context for all
  return (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <Layout {...props}>{element}</Layout>
    </PrismicPreviewProvider>
  )
}

export default Wrapper
export { Wrapper }
