import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation, WindowLocation } from '@reach/router'
import { determineDuration, determineProjectIndex } from '../utilities'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const PrismicProjectDataBodyMediaItemTypeFragment = graphql`
  fragment PrismicProjectDataBodyMediaItemType on PrismicProjectDataBodyMediaItem {
    image {
      alt
      copyright
      dimensions {
        height
        width
      }
      gatsbyImageData
      # thumbnails
      url
    }
    text {
      html
      richText
      text
    }
    text_position
    text_size
  }
`

const PrismicProjectDataBodyMediaFragment = graphql`
  fragment PrismicProjectDataBodyMedia on PrismicProjectDataBodyMedia {
    id
    items {
      ...PrismicProjectDataBodyMediaItemType
    }
    primary {
      align_items
      centered
    }
  }
`

const prismicProjectFragment = graphql`
  fragment PrismicProject on PrismicProject {
    data {
      body {
        ... on PrismicProjectDataBodyMedia {
          ...PrismicProjectDataBodyMedia
        }
      }
      background_color
      categories {
        category
      }
      end_date
      featured_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      meta_description
      start_date
      title
    }
    id
    prismicId
    uid
  }
`

const allPrismicProjectQuery = graphql`
  query allPrismicProject {
    allPrismicProject(
      sort: [{ data: { start_date: DESC } }, { data: { title: ASC } }]
    ) {
      edges {
        node {
          ...PrismicProject
        }
      }
    }
  }
`

export type PrismicProjectEdge =
  Queries.allPrismicProjectQuery['allPrismicProject']['edges'][0]

function useAllProjects() {
  const staticData: Queries.allPrismicProjectQuery = useStaticQuery(
      allPrismicProjectQuery
    ),
    mergedData = useMergePrismicPreviewData(staticData)

  return mergedData
}

function useAllCategoryProjects(category: string): {
  edges: PrismicProjectEdge[]
} {
  const { allPrismicProject } = useAllProjects()

  if (category && category !== `*`)
    return {
      edges: allPrismicProject.edges.filter(
        (edge: any) =>
          edge?.node?.data?.categories?.findIndex(
            (cat: any) => cat?.category === category
          ) > -1
      ),
    }

  return allPrismicProject
}

function useAllCategoryProjectsUids(category: string) {
  const allPrismicProject = useAllCategoryProjects(category)
  return allPrismicProject?.edges?.map((edge) => edge?.node?.uid)
}

function useAllCategoryProjectsIndex(category: string, uid?: string) {
  const uids = useAllCategoryProjectsUids(category),
    { pathname } = useLocation()

  return determineProjectIndex(uids, uid ? `/projects/${uid}` : pathname)
}

function useAllCategoryProjectsLength(category: string) {
  const allPrismicProject = useAllCategoryProjects(category)
  return allPrismicProject?.edges?.length ?? 0
}

function useProject(uid: any): Queries.PrismicProject | undefined {
  const { allPrismicProject } = useAllProjects(),
    // Remove trailing slashes
    uidSearch = uid?.replace(/(\/)$/, ``)

  return useMemo(
    () =>
      allPrismicProject?.edges?.find(
        (edge: PrismicProjectEdge): boolean => edge?.node?.uid === uidSearch
      )?.node,
    [allPrismicProject, uidSearch]
  )
}

function useAllCategoryProjectsSiblings(category: string): Siblings {
  const uids = useAllCategoryProjectsUids(category),
    index = useAllCategoryProjectsIndex(category)

  let siblings: Siblings = {
    previous: undefined,
    next: undefined,
  }

  switch (index) {
    case -1:
      break
    case 0:
      siblings.previous = uids[uids.length - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
    default:
      siblings.previous = uids[index - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
  }

  return siblings
}

function useProjectCategories(uid: any) {
  const project = useProject(uid),
    categories = project?.data?.categories
      ?.map((category) => category?.category)
      ?.sort()

  return categories || []
}

export interface ProjectsContext {
  context: string
}

function useProjectDuration(uid: any) {
  const project = useProject(uid),
    start = project?.data?.start_date
      ? new Date(`${project?.data.start_date}T00:00:00.000+00:00`)
      : undefined,
    end = project?.data?.end_date
      ? new Date(`${project?.data.end_date}T00:00:00.000+00:00`)
      : undefined

  return useMemo(() => determineDuration(start, end), [start, end]) || ``
}

function useProjectsContext(): ProjectsContext {
  const { state }: WindowLocation & { state: any } = useLocation()

  return {
    context: state?.context ? state.context : `All`,
  }
}

function useContextualProjectsIndex() {
  const { context } = useProjectsContext(),
    categoryProjectsIndex = useAllCategoryProjectsIndex(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsIndex
}

function useContextualProjectsLength() {
  const { context } = useProjectsContext(),
    categoryProjectsLength = useAllCategoryProjectsLength(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsLength
}

function useContextualProjectsSiblings() {
  const { context } = useProjectsContext(),
    categoryProjectsSiblings = useAllCategoryProjectsSiblings(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsSiblings
}

function useCurrentProject(): Queries.PrismicProject | undefined {
  const { pathname } = useLocation(),
    locationUid = pathname.replace(`/projects/`, ``)

  return useProject(locationUid)
}

function useAllCategories(): string[] {
  const { allPrismicProject } = useAllProjects()
  let allPrismicProjectCategories: string[] = []
  allPrismicProject?.edges?.forEach((edge: PrismicProjectEdge): void => {
    edge?.node?.data?.categories?.forEach((category) => {
      if (
        !category?.category ||
        allPrismicProjectCategories.indexOf(category?.category) > -1
      )
        return
      allPrismicProjectCategories.push(category?.category)
    })
  })
  return allPrismicProjectCategories
}

function useAllProjectsIndex(uid?: string) {
  const uids = useAllProjectsUids(),
    { pathname } = useLocation()

  return determineProjectIndex(uids, uid ? `/projects/${uid}` : pathname)
}

function useAllProjectsLength() {
  const { allPrismicProject } = useAllProjects()
  return allPrismicProject?.edges?.length ?? 0
}

export interface Siblings {
  previous: string | null | undefined
  next: string | null | undefined
}

function useAllProjectsSiblings(): Siblings {
  const uids = useAllProjectsUids(),
    index = useAllProjectsIndex()

  let siblings: Siblings = {
    previous: undefined,
    next: undefined,
  }

  switch (index) {
    case -1:
      break
    case 0:
      siblings.previous = uids[uids.length - 1]
      siblings.next = uids[index + 1]
      break
    default:
      siblings.previous = uids[index - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
  }

  return siblings
}

function useAllProjectsUids() {
  const { allPrismicProject } = useAllProjects()
  return allPrismicProject?.edges?.map(
    (edge: PrismicProjectEdge) => edge?.node?.uid
  )
}

export {
  allPrismicProjectQuery,
  PrismicProjectDataBodyMediaFragment,
  PrismicProjectDataBodyMediaItemTypeFragment,
  prismicProjectFragment,
  useAllCategories,
  useAllCategoryProjects,
  useAllProjects,
  useAllProjectsIndex,
  useAllProjectsLength,
  useAllProjectsSiblings,
  useAllProjectsUids,
  useContextualProjectsIndex,
  useContextualProjectsLength,
  useContextualProjectsSiblings,
  useCurrentProject,
  useProject,
  useProjectCategories,
  useProjectDuration,
  useProjectsContext,
}
