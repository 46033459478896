import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const prismicMetadataQuery = graphql`
  query prismicMetadataQuery {
    prismicMetadata {
      id
      data {
        default_background_color
        default_text_color
        default_link_hover_color
        footer_content {
          html
          richText
          text
        }
        info_menu {
          title
          link {
            link_type
            target
            url
          }
        }
        site_domain
        site_title
        site_title_description
        site_title_divider
        splash_pages {
          image {
            alt
            copyright
            dimensions {
              height
              width
            }
            gatsbyImageData
            # thumbnails
            url
          }
          logo_color
        }
      }
    }
  }
`

function useMetadata(): {
  prismicMetadata: Queries.prismicMetadataQueryQuery['prismicMetadata']
} {
  const staticData = useStaticQuery(prismicMetadataQuery),
    mergedData = useMergePrismicPreviewData(staticData)

  return mergedData
}

function useSplashPages() {
  const { prismicMetadata } = useMetadata()
  return prismicMetadata?.data?.splash_pages
}

export { prismicMetadataQuery, useMetadata, useSplashPages }
