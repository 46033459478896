import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

const fontFace = (
  fonts: object,
  family: string,
  weight: string,
  style: string,
  formats: string[],
  display?: string
) =>
  `@font-face {` +
  `font-family: "${family.replace(/([A-Z])/g, ' $1').substr(1)}";` +
  fontSrc(fonts, family, weight, style, formats) +
  `font-weight: ${weight};font-style: ${style};font-display: ${
    display || 'auto'
  };}`

const fontSrc = (
  fonts: { [key: string]: any },
  family: string,
  weight: string,
  style: string,
  formats: string[]
) => {
  const order = ['eot', 'woff2', 'woff', 'ttf']
  return formats
    .slice()
    .filter((format) => order.indexOf(format) > -1)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .map((format, i, a) => {
      if (!format) return ``
      switch (format) {
        case 'eot':
          return (
            `src: url("${fonts[family][weight][style].eot}");` +
            `src: url("${
              fonts[family][weight][style].eot
            }?#iefix") format("embedded-opentype")${
              i < a.length - 1 ? ',' : ';'
            }`
          )
        default:
          return (
            `${i === 0 ? 'src: ' : ''}` +
            `url("${fonts[family][weight][style][format]}") format("${
              format === 'ttf' ? 'truetype' : format
            }")` +
            `${i < a.length - 1 ? ',' : ';'}`
          )
      }
    })
    .join(``)
}

const Fontface = memo(
  ({
    count,
    display,
    fonts,
  }: {
    count?: string
    display?: string
    fonts?: { [key: string]: any }
  }) => {
    if (typeof fonts !== 'object') return null

    return (
      <Helmet>
        {Object.keys(fonts)
          .flatMap((family: string) =>
            Object.keys(fonts[family]).flatMap((weight: string) =>
              Object.keys(fonts[family][weight]).flatMap((style: string) =>
                ['eot', 'woff2', 'woff', 'ttf'].flatMap(
                  (format: string) => fonts[family][weight][style][format]
                )
              )
            )
          )
          .filter((path) => path && path.substr(0, 1) === `/`)
          .map((fontfile, i) => (
            <link key={i} rel="preload" href={fontfile} as="font" />
          ))}
        {count && <link rel="stylesheet" href={count} />}
        <style type="text/css">
          {`${Object.keys(fonts)
            .map((family: string) =>
              Object.keys(fonts[family])
                .map((weight: string) =>
                  Object.keys(fonts[family][weight])
                    .map((style: string) =>
                      fontFace(
                        fonts,
                        family,
                        weight,
                        style,
                        Object.keys(fonts[family][weight][style]),
                        display
                      )
                    )
                    .join(`\n`)
                )
                .join(`\n`)
            )
            .join(`\n`)}`}
        </style>
      </Helmet>
    )
  }
)

export { Fontface }
