import React, { KeyboardEvent } from 'react'
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import { Logo } from './logo'
import { useEventListener } from '../hooks/useEventListener'

const Splash = ({
  dismiss,
  load,
  prismicMetadata,
  random,
}: {
  dismiss: () => void
  load: () => void
  prismicMetadata: Queries.prismicMetadataQueryQuery['prismicMetadata']
  random: number
}) => {
  const splashPages = prismicMetadata?.data?.splash_pages,
    splashPage = splashPages
      ? splashPages[Math.floor(random * splashPages.length)]
      : undefined

  const color = splashPage?.logo_color,
    image = splashPage?.image?.gatsbyImageData,
    imageProps: GatsbyImageProps = {
      alt: splashPage?.image?.alt ?? ``,
      image: image as unknown as IGatsbyImageData,
      // fadeIn: false,
      loading: `eager`,
      onLoad: load,
    },
    logoStyle = { fill: color },
    dismissKey = (e: Event) => {
      const ke = e as unknown as KeyboardEvent
      if (ke.key) {
        if ([` `, `Escape`, `Enter`].indexOf(ke.key) > -1) {
          dismiss()
        }
      }
    }

  useEventListener(`click`, dismiss)
  useEventListener(`keydown`, dismissKey)

  // Set Img sizes based on styling
  if (image && image.sizes) image.sizes = `100vw`

  return (
    <div
      className="fixed w-full min-h-screen flex items-center justify-center z-30"
      style={{
        cursor: `pointer`,
        minHeight: `-webkit-fill-available`,
      }}
    >
      <Logo
        className="w-full m-24 z-10 pointer-events-none"
        style={logoStyle}
      />
      <div className="absolute w-full h-full">
        {image && <GatsbyImage className="h-full" {...imageProps} />}
      </div>
    </div>
  )
}

export default Splash
export { Splash }
