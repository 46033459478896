module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lukeedwardhall","accessToken":"MC5Yb3U2a1JBQUFDSUFIVERS.77-977-9Ae-_ve-_vTnvv73vv73vv73vv71ebu-_vTLvv70g77-9UzMH77-977-977-977-9bAbvv73vv73vv73vv70D77-9","lang":"*","imageImgixParams":{"auto":"compress,format","dpr":1,"fit":"max","q":50},"imagePlaceholderImgixParams":{"w":2,"q":2}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luke Edward Hall","short_name":"Luke Edward Hall","start_url":"/","background_color":"#fff5eb","theme_color":"#321600","display":"minimal-ui","icon":"./src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1bd3e3a7d24c83534d1bb524c36ff388"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104066177-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"defer":true,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
